exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-works-aisva-tsx": () => import("./../../../src/pages/works/aisva.tsx" /* webpackChunkName: "component---src-pages-works-aisva-tsx" */),
  "component---src-pages-works-chatforma-tsx": () => import("./../../../src/pages/works/chatforma.tsx" /* webpackChunkName: "component---src-pages-works-chatforma-tsx" */),
  "component---src-pages-works-pharm-portal-tsx": () => import("./../../../src/pages/works/pharm-portal.tsx" /* webpackChunkName: "component---src-pages-works-pharm-portal-tsx" */),
  "component---src-pages-works-rucenter-tsx": () => import("./../../../src/pages/works/rucenter.tsx" /* webpackChunkName: "component---src-pages-works-rucenter-tsx" */),
  "component---src-pages-works-tutu-tsx": () => import("./../../../src/pages/works/tutu.tsx" /* webpackChunkName: "component---src-pages-works-tutu-tsx" */),
  "component---src-pages-works-valo-cloud-tsx": () => import("./../../../src/pages/works/valo-cloud.tsx" /* webpackChunkName: "component---src-pages-works-valo-cloud-tsx" */)
}

